import type { AlgoliaProduct } from '~/types/models/algoliaProduct';
import { Sorter } from '~/helpers/Sorter';
import type { ProductSliderTabs, ProductSliderTabsTabNames } from '~/types/models/cms/cmsElementTypes';
import { getTranslatedProperty } from '@shopware-pwa/helpers-next';
import type { AhProduct } from '~/types/models/product/product';
import { ArrayHelper } from '~/helpers/ArrayHelper';
import type { RecommendationsQuery } from '@algolia/recommend';
import { ObjectHelper } from '~/helpers/ObjectHelper';
import type { TrendingItemsQuery } from '@algolia/recommend/dist/recommend';

export const useAlgoliaProductSlider = (selectedTabs: ProductSliderTabsTabNames[] = []) => {
    // general composable usages
    const { lastSeen } = useLastSeen('product');
    const { searchWishlists, wishlistWithDefaultFlag } = useWishlists();
    const { accessories } = useCrossSellings();
    const { page, product } = usePage();
    const manufacturerName = computed(() => getTranslatedProperty(product.value?.manufacturer, 'name'));
    const seriesName = computed(() =>
        getTranslatedProperty((product.value as AhProduct)?.extensions?.attributes?.productSeriesEntity, 'name'),
    );
    const { isLoggedIn } = useUser();

    // products and product numbers for each tab
    const lastSeenProducts = useState<AlgoliaProduct[]>('lastSeenProducts', () => ref([]));
    const lastSeenProductNumbers = computed(() => lastSeen.value);

    const wishlistProducts: Ref<AlgoliaProduct[]> = useState<AlgoliaProduct[]>('wishlistProducts', () => ref([]));
    const wishlistProductNumbers = computed(() =>
        (wishlistWithDefaultFlag.value?.productCollection ?? [])
            .map((item) => item?.product?.productNumber || '')
            .filter((productNumber) => productNumber !== ''),
    );

    const seriesProducts = useState<AlgoliaProduct[]>('seriesProducts', () => ref([]));
    const seriesProductNumbers = computed(() => seriesProducts.value.map((product) => product.productNumber));

    const accessoriesProducts = useState<AlgoliaProduct[]>('accessoriesProducts', () => ref([]));
    const accessoriesProductNumbers = computed(() => (accessories.value ?? []).map((product) => product.productNumber));

    const customerBoughtProducts = useState<AlgoliaProduct[]>('customerBoughtProducts', () => ref([]));
    const customerBoughtProductNumbers = computed(() =>
        customerBoughtProducts.value.map((product) => product.productNumber),
    );

    const noveltyRecoProducts = useState<AlgoliaProduct[]>('noveltyRecoProducts', () => ref([]));
    const noveltyRecoProductNumbers = computed(() => noveltyRecoProducts.value.map((product) => product.productNumber));

    const noveltyProducts = useState<AlgoliaProduct[]>('noveltyProducts', () => ref([]));
    const noveltyProductNumbers = computed(() => noveltyProducts.value.map((product) => product.productNumber));

    const recoProducts = useState<AlgoliaProduct[]>('recoProducts', () => ref([]));
    const recoProductNumbers = computed(() => recoProducts.value.map((product) => product.productNumber));

    const bestsellerProducts = useState<AlgoliaProduct[]>('bestsellerProducts', () => ref([]));
    const bestsellerProductNumbers = computed(() => bestsellerProducts.value.map((product) => product.productNumber));

    const allProductNumbers = computed(() =>
        ArrayHelper.merge(
            lastSeenProductNumbers.value,
            wishlistProductNumbers.value,
            seriesProductNumbers.value,
            accessoriesProductNumbers.value,
            customerBoughtProductNumbers.value,
            noveltyRecoProductNumbers.value,
            noveltyProductNumbers.value,
            recoProductNumbers.value,
            bestsellerProductNumbers.value,
        ),
    );

    const _checkIfTabShouldBeLoaded = () => {
        if (!selectedTabs.length) return;

        for (const tab of selectedTabs) {
            if (Object.hasOwn(tabs, tab)) {
                tabs[tab].shouldBeLoaded = true;
            }
        }
    };

    const tabs = reactive<ProductSliderTabs>({
        recommendations: {
            shouldBeLoaded: false,
            visible: false,
            component: 'ProductCmsRecommendationSlider',
            componentAttrs: {
                products: recoProducts,
            },
        },
        series: {
            shouldBeLoaded: false,
            visible: false,
            component: 'ProductCmsSeriesSlider',
            componentAttrs: {
                products: seriesProducts,
            },
        },
        accessories: {
            shouldBeLoaded: false,
            visible: false,
            component: 'ProductCmsAccessoriesSlider',
            componentAttrs: {
                products: accessoriesProducts,
                content: {
                    config: {
                        title: {
                            value: '',
                            source: 'static',
                        },
                    },
                },
            },
        },
        customer_bought: {
            shouldBeLoaded: false,
            visible: false,
            component: 'ProductCmsCustomerBoughtSlider',
            componentAttrs: {
                products: customerBoughtProducts,
            },
        },
        novelty: {
            shouldBeLoaded: false,
            visible: false,
            component: 'ProductCmsNoveltySlider',
            componentAttrs: {
                products: noveltyProducts,
            },
        },
        novelty_recommendations: {
            shouldBeLoaded: false,
            visible: false,
            component: 'ProductCmsNoveltyRecommendationsSlider',
            componentAttrs: {
                products: noveltyRecoProducts,
            },
        },
        last_seen: {
            shouldBeLoaded: false,
            visible: false,
            component: 'ProductCmsLastSeenSlider',
            componentAttrs: {
                products: lastSeenProducts,
            },
        },
        bestseller: {
            shouldBeLoaded: false,
            visible: false,
            component: 'ProductCmsBestsellerSlider',
            componentAttrs: {
                products: bestsellerProducts,
            },
        },
        wishlist: {
            shouldBeLoaded: false,
            visible: false,
            component: 'ProductCmsWishlistSlider',
            componentAttrs: {
                products: wishlistProducts,
            },
        },
    });

    // check initially if tab should be loaded
    _checkIfTabShouldBeLoaded();

    const hasAnyVisibleTab = computed(() => Object.values(tabs).some((tab) => tab.visible));
    const initialVisibleTab = computed(() => selectedTabs.find((key) => tabs[key].shouldBeLoaded && tabs[key].visible));

    const sortedTabs = computed(() => {
        const sortedData: Partial<ProductSliderTabs> = {};

        selectedTabs
            .filter((key) => tabs[key].shouldBeLoaded && tabs[key].visible)
            .forEach((key) => {
                if (tabs[key]) {
                    sortedData[key] = tabs[key];
                }
            });

        return sortedData;
    });

    const { productIndex, getDefaultFacetFilters } = useAlgolia();
    const { search } = useAlgoliaSearch(productIndex.value);
    const defaultFacetFilters = getDefaultFacetFilters();
    const recoId = useId();
    const { get } = useAlgoliaRecommend(`tabs-reco-${recoId}`);

    const _createRecoQuery = (
        modelName: string,
        config: Partial<RecommendationsQuery | TrendingItemsQuery> = {},
        limit: number = 15,
    ): RecommendationsQuery => {
        const defaultQueryConfig: RecommendationsQuery = {
            maxRecommendations: +limit,
            indexName: productIndex.value,
            // @ts-ignore e.g. trending-items model exists in algolia, but is not typed
            model: modelName,
            queryParameters: {
                // @ts-ignore
                facetFilters: [...defaultFacetFilters],
                clickAnalytics: true,
            },
        };

        return ObjectHelper.merge(defaultQueryConfig, config) as RecommendationsQuery;
    };

    // helper function to get products from Algolia based on facets
    const _doAlgoliaSearch = async (facets: string[]) => {
        if (!facets.length) return;

        try {
            const response = await search({
                query: '',
                requestOptions: {
                    // @ts-ignore
                    facetFilters: [...defaultFacetFilters, ...[facets]],
                    hitsPerPage: 15,
                    clickAnalytics: true,
                },
            });

            return response?.hits as AlgoliaProduct[];
        } catch (e) {
            Logger.captureException(e);
        }
    };

    const _doAlgoliaRecoSearch = async (
        queries: Array<RecommendationsQuery>,
        limit: number | undefined = undefined,
    ) => {
        // Algolia Reco is not working on SRR, so early return
        if (!queries.length || import.meta.server) return;

        try {
            const uniqueHits = new Map();
            // algolia only allows to send 50 queries at once
            const response = await get({ queries: queries.slice(0, 50) });

            const allSortedHits = toRaw(response)
                .results.flatMap((result) => (result.hits ?? []) as Array<AlgoliaProduct & { _score: number }>)
                .sort((a, b) => a._score - b._score)
                .reverse()
                .filter((hit) => {
                    // filter out duplicates
                    if (uniqueHits.has(hit.objectID)) {
                        return false;
                    }

                    uniqueHits.set(hit.objectID, true);
                    return true;
                });

            return limit ? allSortedHits.slice(0, +limit) : allSortedHits;
        } catch (e) {
            Logger.captureException(e);
        }
    };

    const getLastSeenProducts = async (): Promise<AlgoliaProduct[]> => {
        if (!lastSeenProductNumbers.value.length) return [];

        const facets = lastSeenProductNumbers.value.map((productNumber) => `objectID:${productNumber}`);

        const hits = await _doAlgoliaSearch(facets);
        if (!hits || hits.length === 0) return [];

        // reorder hits based on lastSeen history
        lastSeenProducts.value = Sorter.sortByDefinedOrder(hits, lastSeen.value, 'objectID') || [];

        return lastSeenProducts.value;
    };

    const getWishlistProducts = async (): Promise<AlgoliaProduct[]> => {
        wishlistProducts.value = [];
        if (!isLoggedIn.value) return [];

        await searchWishlists();

        const facets = wishlistProductNumbers.value.map((productNumber) => `objectID:${productNumber}`);

        const hits = await _doAlgoliaSearch(facets);
        if (!hits || hits.length === 0) return [];

        wishlistProducts.value = hits;

        return hits;
    };

    const getSeriesProducts = async (): Promise<AlgoliaProduct[]> => {
        seriesProducts.value = [];
        if (!seriesName.value) return [];

        const facets = [`serie:${seriesName.value}`];

        const hits = await _doAlgoliaSearch(facets);
        if (!hits || hits.length === 0) return [];

        seriesProducts.value = hits;

        return hits;
    };

    const getAccessoriesProducts = async (): Promise<AlgoliaProduct[]> => {
        accessoriesProducts.value = [];
        if (!accessories.value?.length) return [];

        const facets = accessoriesProductNumbers.value.map((productNumber) => `objectID:${productNumber}`);

        const hits = await _doAlgoliaSearch(facets);
        if (!hits || hits.length === 0) return [];

        accessoriesProducts.value = hits;

        return hits;
    };

    const getNoveltyProducts = async (): Promise<AlgoliaProduct[]> => {
        noveltyProducts.value = [];
        const facets = ['isNovelty:true'];

        const hits = await _doAlgoliaSearch(facets);
        if (!hits || hits.length === 0) return [];

        noveltyProducts.value = hits;

        return hits;
    };

    const getCustomerBoughtProducts = async (): Promise<AlgoliaProduct[]> => {
        customerBoughtProducts.value = [];
        const modelName = 'bought-together';

        if (!product.value?.productNumber) return [];

        const queries = [
            _createRecoQuery(modelName, {
                objectID: product.value.productNumber,
            }),
        ];

        const hits = await _doAlgoliaRecoSearch(queries);
        if (!hits || hits.length === 0) return [];

        customerBoughtProducts.value = hits;

        return hits;
    };

    const getNoveltyRecoProducts = async (): Promise<AlgoliaProduct[]> => {
        noveltyRecoProducts.value = [];
        const modelName = 'trending-items';

        const queries = [
            _createRecoQuery(modelName, {
                facetName: 'isNovelty',
                facetValue: 'true',
            }),
        ];

        const hits = await _doAlgoliaRecoSearch(queries);
        if (!hits || hits.length === 0) return [];

        noveltyRecoProducts.value = hits;

        return hits;
    };

    const getRecoProducts = async (): Promise<AlgoliaProduct[]> => {
        recoProducts.value = [];
        const modelName = 'related-products';

        if (!product.value?.productNumber) return [];

        const queries = [
            _createRecoQuery(modelName, {
                objectID: product.value.productNumber,
            }),
        ];

        const hits = await _doAlgoliaRecoSearch(queries);
        if (!hits || hits.length === 0) return [];

        recoProducts.value = hits;

        return hits;
    };

    const getBestsellerProducts = async (): Promise<AlgoliaProduct[]> => {
        bestsellerProducts.value = [];
        const modelName = 'trending-items';
        const queries = [];

        // add manufacturer filter if product is available
        if (manufacturerName.value) {
            queries.push(
                _createRecoQuery(modelName, {
                    facetName: 'manufacturer',
                    facetValue: manufacturerName.value,
                }),
            );
        }

        // add category filter if category page is available
        if (page.value?.category?.id) {
            queries.push(
                _createRecoQuery(modelName, {
                    facetName: 'categoryTreeIds',
                    facetValue: page.value?.category?.id,
                }),
            );
        }

        const hits = await _doAlgoliaRecoSearch(queries, 15);
        if (!hits || hits.length === 0) return [];

        bestsellerProducts.value = hits;

        return hits;
    };

    const getTabProducts = async () => {
        const loader = [];

        if (tabs.last_seen.shouldBeLoaded) loader.push(getLastSeenProducts());
        if (tabs.wishlist.shouldBeLoaded) loader.push(getWishlistProducts());
        if (tabs.series.shouldBeLoaded) loader.push(getSeriesProducts());
        if (tabs.accessories.shouldBeLoaded) loader.push(getAccessoriesProducts());
        if (tabs.customer_bought.shouldBeLoaded) loader.push(getCustomerBoughtProducts());
        if (tabs.novelty_recommendations.shouldBeLoaded) loader.push(getNoveltyRecoProducts());
        if (tabs.recommendations.shouldBeLoaded) loader.push(getRecoProducts());
        if (tabs.novelty.shouldBeLoaded) loader.push(getNoveltyProducts());
        if (tabs.bestseller.shouldBeLoaded) loader.push(getBestsellerProducts());

        const response = await Promise.all(loader);

        if (lastSeenProducts.value.length) tabs.last_seen.visible = true;
        if (wishlistProducts.value.length) tabs.wishlist.visible = true;
        if (seriesProducts.value.length) tabs.series.visible = true;
        if (accessoriesProducts.value.length) tabs.accessories.visible = true;
        if (customerBoughtProducts.value.length) tabs.customer_bought.visible = true;
        if (noveltyRecoProducts.value.length) tabs.novelty_recommendations.visible = true;
        if (recoProducts.value.length) tabs.recommendations.visible = true;
        if (noveltyProducts.value.length) tabs.novelty.visible = true;
        if (bestsellerProducts.value.length) tabs.bestseller.visible = true;

        return response;
    };

    return {
        getLastSeenProducts,
        getWishlistProducts,
        getSeriesProducts,
        getAccessoriesProducts,
        getCustomerBoughtProducts,
        getNoveltyRecoProducts,
        getBestsellerProducts,
        wishlistProducts,
        lastSeenProducts,
        seriesProducts,
        accessoriesProducts,
        customerBoughtProducts,
        noveltyRecoProducts,
        bestsellerProducts,
        getTabProducts,
        tabs,
        sortedTabs,
        hasAnyVisibleTab,
        initialVisibleTab,
        allProductNumbers,
        accessoriesProductNumbers,
        lastSeenProductNumbers,
        customerBoughtProductNumbers,
        noveltyRecoProductNumbers,
        bestsellerProductNumbers,
    };
};
